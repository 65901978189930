import HyperDX from '@hyperdx/browser';
import Image from 'next/image';
import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router'
import {
  Container,
  Icon,
  Menu,
  Sidebar,
} from 'semantic-ui-react';

import * as actions from '../../actions';
import SearchBar from './SearchBar';
import mainLogo from '../../assets/icons/nav-logo.jpg';
import { HomeLink } from '../Links';
import { ROUTE_PATH } from '../../constants';

const TOP_PADDING_DISTANCE_DESKTOP = '75px';
const TOP_PADDING_DISTANCE_MOBILE = '0px';

const ITEMS = [
  {
    title: 'PTT 全站統計',
    path: ROUTE_PATH.PTT_HOME,
    alwaysShow: true,
    showOn: ['mobile', 'desktop'],
  },
  {
    title: 'Dcard 全站統計',
    path: ROUTE_PATH.DCARD_HOME,
    authed: true,
    accessPermissionKey: 'dcard',
    showOn: ['mobile', 'desktop'],
  },
  {
    title: 'FAQ',
    path: ROUTE_PATH.FAQ,
    alwaysShow: true,
    showOn: ['mobile', 'desktop'],
  },
  {
    title: '關於我們',
    path: ROUTE_PATH.ABOUT_US,
    authed: false,
    showOn: ['mobile', 'desktop'],
  },
  {
    title: '登入',
    path: ROUTE_PATH.LOGIN,
    authed: false,
    showOn: ['mobile', 'desktop'],
  },
  {
    title: '我的最愛',
    path: ROUTE_PATH.MY_FAVORITES,
    authed: true,
    showOn: ['mobile', 'desktop'],
  },
  {
    title: '登出',
    path: ROUTE_PATH.LOGOUT,
    authed: true,
    showOn: ['mobile', 'desktop'],
  },
];

const checkIfNeedToShowItem = (isMobile, authed, me, item) => {
  if (item.accessPermissionKey && me?.data?.accessPermission?.[item.accessPermissionKey] !== true) {
    return false;
  }

  if (!isMobile) {
    return ((authed === item.authed) || item.alwaysShow) && item.showOn.includes('desktop');
  }
  return ((authed === item.authed) || item.alwaysShow) && item.showOn.includes('mobile');
};

const NavBarMobile = ({
  me,
  authed,
  children,
  // isLoaded,
  onPusherClick,
  onToggle,
  pathname,
  pushAction,
  visible,
}) => (
  <Sidebar.Pushable>
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      direction="top"
      vertical
      visible={visible}
      inverted
    >
      {
        ITEMS.map((item) => (
          checkIfNeedToShowItem(true, authed, me, item) && (
            <Menu.Item
              style={{
                paddingTop: '1.2em',
                paddingBottom: '1.2em',
                fontSize: 16,
              }}
              key={item.title}
              name={item.title}
              active={item.path === pathname}
              onClick={() => {
                onPusherClick();
                pushAction(item.path);
              }}
            >
              {item.title}
            </Menu.Item>
          )
        ))
      }
    </Sidebar>
    <Sidebar.Pusher
      dimmed={visible}
      onClick={onPusherClick}
      style={{
        minHeight: '100vh',
      }}
    >
      <Menu
        secondary
        stackable
        size="small"
        style={{
          backgroundColor: 'white',
        }}
      >
        <Menu.Item>
          <div>
            <HomeLink>
              <Image
                src={mainLogo}
                width={80}
                height={'auto'}
                quality={100}
                alt="main logo"
              />
            </HomeLink>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Icon
              name="sidebar"
              onClick={onToggle}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <SearchBar
            authed={authed}
          />
        </Menu.Item>
      </Menu>
      {children}
    </Sidebar.Pusher>
  </Sidebar.Pushable>
);

const NavBarDesktop = ({ authed, isLoaded, me, pathname, pushAction }) => (
  <Menu
    secondary
    fixed="top"
    size="huge"
    style={{
      backgroundColor: 'white',
    }}
  >
    <Menu.Item>
      <HomeLink>
        <Image
          src={mainLogo}
          width={80}
          height={'auto'}
          quality={100}
          alt="main logo"
        />
      </HomeLink>
    </Menu.Item>
    <Menu.Item position="left">
      <SearchBar
        authed={authed}
      />
    </Menu.Item>
    {
      isLoaded && (
        <Menu.Menu position="right">
          {
            ITEMS.map((item) => (
              checkIfNeedToShowItem(false, authed, me, item)
              && (
                <Menu.Item
                  key={item.title}
                  name={item.title}
                  active={item.path === pathname}
                  onClick={() => pushAction(item.path)}
                />
              )
            ))
          }
        </Menu.Menu>
      )
    }
  </Menu>
);

const NavBarChildren = ({ children, topLegnth }) => (
  <Container
    style={{
      position: 'relative',
      top: topLegnth,
      paddingBottom: '2em',
    }}
  >
    {children}
  </Container>
);

const NavBar = (props) => {
  const router = useRouter()

  const [visible, setVisible] = useState(false);

  const handlePusher = () => {
    if (visible) {
      setVisible(false);
    }
  };

  const handleToggle = () => {
    setVisible(!visible);
  };

  const {
    browser,
    authed,
    me,
    firebase: {
      auth: {
        isLoaded,
        displayName,
        email,
      },
    },
    getMe,
    children,
  } = props;

  useEffect(() => {
    if (authed) {
      getMe();
    }
  }, [authed])

  if (isLoaded && (displayName || email)) {
    HyperDX.setGlobalAttributes({
      userEmail: email,
      userName: displayName,
    });
  }

  const isMobile = browser.lessThan.medium;

  const pathname = router.pathname;

  if (isMobile) {
    return (
      <div>
        <NavBarMobile
          authed={authed}
          isLoaded={isLoaded}
          me={me}
          onPusherClick={handlePusher}
          onToggle={handleToggle}
          pathname={pathname}
          pushAction={router.push}
          visible={visible}
        >
          <NavBarChildren topLegnth={TOP_PADDING_DISTANCE_MOBILE}>
            {children}
          </NavBarChildren>
        </NavBarMobile>
      </div>
    );
  }

  return (
    <div>
      <NavBarDesktop
        authed={authed}
        isLoaded={isLoaded}
        me={me}
        pathname={pathname}
        pushAction={router.push}
      />
      <NavBarChildren topLegnth={TOP_PADDING_DISTANCE_DESKTOP}>
        {children}
      </NavBarChildren>
    </div>
  );
};

const mapStateToProps = ({ router, browser, firebase, auth }) => ({ router, browser, firebase, me: auth.me });
export default connect(mapStateToProps, actions)(NavBar);
