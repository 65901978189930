import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { Input, Dropdown } from 'semantic-ui-react';
import { animateScroll as scroll } from 'react-scroll';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';

import * as actions from '../../actions';
import { ROUTE_PATH, SEARCH_ALL_LIMIT } from '../../constants';

const DEBOUNCE_TIME = 800;
const DEFAULT_PLATFORM = 'ptt';

const SearchBar = props => {
  const router = useRouter();
  const { isFetching } = props;
  const [platform, setPlatform] = useState(DEFAULT_PLATFORM);
  const [searchTerm, setSearchTerm] = useState(null);

  const searchAction = useCallback(
    debounce((_platform, _searchTerm) => {
      props.SearchAll(_platform, _searchTerm, SEARCH_ALL_LIMIT, 0);
      router.push({
        pathname: ROUTE_PATH.SEARCH,
        query: {
          platform: _platform,
          q: _searchTerm,
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (searchTerm) {
      searchAction(platform, searchTerm);
    }
  }, [platform, searchTerm]);

  const onKeyPress = e => {
    if (e.key === 'Enter' && searchTerm) {
      searchAction();
    }
  };

  return (
    <Input
      icon={{
        name: 'search',
        link: false,
      }}
      action={
        <Dropdown
          button
          basic
          floating
          value={platform}
          options={[
            {
              key: 'ptt',
              text: 'PTT',
              value: 'ptt',
            },
            {
              key: 'dcard',
              text: 'Dcard',
              value: 'dcard',
            },
          ]}
          onChange={(e, { name, value }) => setPlatform(value)}
        />
      }
      iconPosition="left"
      placeholder="搜尋 [板, 文章, 使用者]"
      onChange={(e, { name, value }) => setSearchTerm(value)}
      onKeyPress={onKeyPress}
      loading={isFetching}
      value={searchTerm}
    />
  );
};

// (FIXME) -> better not to spread the state
const mapStateToProps = ({ search }) => ({
  ...search,
});

export default connect(mapStateToProps, actions)(SearchBar);
