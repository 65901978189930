import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { getNivoTheme } from './utils';

const DEFAULT_NUM_OF_LOCATIONS = 30;
const CHART_HEIGHT = 400;
const CHART_HEIGHT_MOBILE = 300;

export default ({
  data,
  indexBy,
  isMobile,
  keys,
  tooltip,
}) => {
  const filteredData = data
    .slice(0, DEFAULT_NUM_OF_LOCATIONS)
    .reverse();

  return (
    <div style={{ height: isMobile ? CHART_HEIGHT_MOBILE : CHART_HEIGHT }}>
      <ResponsiveBar
        enableLabel
        theme={getNivoTheme(isMobile)}
        data={filteredData}
        keys={keys}
        indexBy={indexBy}
        margin={{
          top: 20,
          right: 50,
          bottom: 0,
          left: isMobile ? 100 : 150,
        }}
        padding={isMobile ? 0.4 : 0.3}
        layout="horizontal"
        valueScale={{ type: 'symlog' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        tooltip={tooltip}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};
