import { denormalize } from 'normalizr';

import {
  articleSchema,
  boardSchema,
  forumSchema,
  personaSchema,
  postSchema,
  searchSchema,
  schoolSchema,
  userSchema,
} from '../schemas';

/*
  firebase state
*/
export const selectFirebaseAuthResults = ({ firebase }) => firebase.auth;
export const selectFirebaseAuthIsLoaded = ({ firebase }) => firebase.auth.isLoaded;
export const selectFirebaseAuthed = ({ firebase }) => {
  const {
    auth: { providerData, emailVerified },
  } = firebase;

  let authed = false;
  if (providerData) {
    authed = true;
    const { providerId } = providerData[0];
    if (providerId === 'password' && !emailVerified) {
      authed = false;
    }
  }

  return authed;
};

/*
  auth state
*/
export const selectSignUpResults = ({ auth }) => auth.signup;

export const selectLoginResults = ({ auth }) => auth.login;

export const selectForgotPasswordResults = ({ auth }) => auth.forgotPassword;

/*
  search state
*/
export const selectSearchResults = ({ search }) => {
  const {
    articles,
    boards,
    entities,
    forums,
    personas,
    posts,
    schools,
    users,
  } = search;
  return denormalize(
    {
      articles,
      boards,
      forums,
      personas,
      posts,
      schools,
      users,
    },
    searchSchema,
    entities,
  );
};

/*
  metric state
*/
export const selectAvgPopularTimeResults = ({ metric }) =>
  metric.avgPopularTime.data;

export const selectHotArticlesResults = ({ metric }) => metric.hotArticles.data;

export const selectHotUsersResults = ({ metric }) => metric.hotUsers.data;

export const selectUserGeoDistributionsResults = ({ metric }) =>
  metric.userGeoDistributions.data;

/*
  board state
*/
export const selectBoardInfoResults = ({ board }) => {
  const {
    info: { board: id, entities },
  } = board;
  return denormalize(id, boardSchema, entities);
};

export const selectBoardTopUsers = ({ board }) => board.topUsers.data;

/*
  article state
*/
export const selectArticleInfoResults = ({ article }) => {
  const {
    info: { article: id, entities },
  } = article;
  return denormalize(id, articleSchema, entities);
};

/*
  user state
*/
export const selectUserInfoResults = ({ user }) => {
  const {
    info: { user: id, entities },
  } = user;
  return denormalize(id, userSchema, entities);
};

/*
  persona state
*/
export const selectPersonaInfoResults = ({ persona }) => {
  const {
    info: { id, entities },
  } = persona;
  return denormalize(id, personaSchema, entities);
};

/*
  forum state
*/
export const selectForumInfoResults = ({ forum }) => {
  const {
    info: { forum: id, entities },
  } = forum;
  return denormalize(id, forumSchema, entities);
};

/*
  post state
*/
export const selectPostInfoResults = ({ post }) => {
  const {
    info: { id, entities },
  } = post;
  return denormalize(id, postSchema, entities);
};

/*
  school state
*/
export const selectSchoolInfoResults = ({ school }) => {
  const {
    info: { id, entities },
  } = school;
  return denormalize(id, schoolSchema, entities);
};

/*
  dashboard state
*/
export const selectDashboardAllFollowings = ({ dashboard }) =>
  dashboard.followings.data;
