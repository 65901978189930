import React from 'react';
import {
  Container,
} from 'semantic-ui-react';

const STYLES = {
  paddingTop: '0.5em',
  paddingBottom: '0.5em',
};

export default (props) => (
  <Container style={STYLES}>
    {props.children}
  </Container>
);
