import Link from 'next/link';

export const HomeLink = ({ children }) => <Link href="/">{children}</Link>;

export const SignUpLink = ({ children }) => (
  <Link href="/signup">{children}</Link>
);

export const LoginLink = ({ children }) => (
  <Link href="/login">{children}</Link>
);

export const ForgotPasswordLink = ({ children }) => (
  <Link href="/forgot_password">{children}</Link>
);

// PTT links
export const ArticleLink = ({ children, id }) => (
  <Link href={`/ptt/article/${id}`}>{children}</Link>
);

export const UserLink = ({ children, id }) => (
  <Link href={`/ptt/user/${id}`}>{children}</Link>
);

export const BoardLink = ({ children, id }) => (
  <Link href={`/ptt/board/${id}`}>{children}</Link>
);

// Dcard links
export const PersonaLink = ({ children, id }) => (
  <Link href={`/dcard/persona/${id}`}>{children}</Link>
);

export const SchoolLink = ({ children, id }) => (
  <Link href={`/dcard/school/${id}`}>{children}</Link>
);

export const ForumLink = ({ children, id }) => (
  <Link href={`/dcard/forum/${id}`}>{children}</Link>
);

export const PostLink = ({ children, id }) => (
  <Link href={`/dcard/post/${id}`}>{children}</Link>
);
