import React, { Component } from 'react';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';
import { ResponsiveChoropleth } from '@nivo/geo';

import { getNivoTheme } from './utils';

const geoUrl = 'https://raw.githubusercontent.com/jdamiani27/Data-Visualization-and-D3/master/lesson4/world_countries.json';
const CHART_HEIGHT = 400;
const CHART_HEIGHT_MOBILE = 300;

export default class UserDistributionMap extends Component {
  state = { features: [], isFetchingGeoMap: true }

  componentDidMount() {
    axios
      .get(geoUrl)
      .then((resp) => resp.data)
      .then((data) => this.setState({ features: data.features, isFetchingGeoMap: false }))
      .catch((e) => { throw e; });
  }

  render() {
    const {
      data,
      isMobile,
      domain,
    } = this.props;
    const {
      features,
      isFetchingGeoMap,
    } = this.state;

    return (
      <div style={{ height: isMobile ? CHART_HEIGHT_MOBILE : CHART_HEIGHT }}>
        {
          isFetchingGeoMap ? (
            <Loader active />
          ) : (
            <ResponsiveChoropleth
              theme={getNivoTheme(isMobile)}
              data={data.map((item) => ({
                id: item.alpha3,
                value: item.count,
              }))}
              features={features}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              colors="nivo"
              domain={domain || [0, 10000]}
              unknownColor="#666666"
              label="properties.name"
              valueFormat=".2s"
              projectionScale={isMobile ? 40 : 100}
              projectionTranslation={[0.5, 0.5]}
              projectionRotation={[0, 0, 0]}
              enableGraticule
              graticuleLineColor="#dddddd"
              borderWidth={0.5}
              borderColor="#152538"
              legends={[
                {
                  anchor: 'bottom-left',
                  direction: 'column',
                  justify: true,
                  translateX: isMobile ? 10 : 20,
                  translateY: -100,
                  itemsSpacing: 0,
                  itemWidth: isMobile ? 50 : 90,
                  itemHeight: isMobile ? 10 : 18,
                  itemDirection: 'left-to-right',
                  itemTextColor: '#444444',
                  itemOpacity: 0.85,
                  symbolSize: isMobile ? 8 : 18,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000000',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          )
        }
      </div>
    );
  }
}
