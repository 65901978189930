export const CHART_HEIGHT = 300;
export const CHART_HEIGHT_MOBILE = 225;
export const isEmptyArray = (data) => !data || (Array.isArray(data) && data.length === 0);
export const shiftDate = (date, numDays) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
};
export const getNivoTheme = (isMobile) => ({
  fontSize: isMobile ? 8 : 11,
});
